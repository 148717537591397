<template>
  <div class="answers">
    <div class="answers__row flex flex__space-between">
      <div class="answers__num">{{index}}</div>
      <div class="answers__score" v-if="data.score">{{data.score}}分</div>
    </div>

    <div class="answers__content">
      <div class="answers__question flex">
        <div class="answers__type" v-if="data.type === 'JUDGEMENT'">判断题</div>
        <div class="answers__type" v-if="data.type === 'RADIO'">单选题</div>
        <div class="answers__type" v-if="data.type === 'CHECKBOX'">多选题</div>
        <div class="answers__type" v-if="data.type === 'OPEN'">问答题</div>
        <div class="answers__type" v-if="data.type === 'SUBJECTIVE_SCORE'">评分题</div>
        <div class="answers__text">{{data.title}}</div>
      </div>
      <div class="answers__answer" v-if="data.type === 'RADIO' || data.type === 'JUDGEMENT'">
        <a-radio-group
          :disabled="true"
          size="large"
          :defaultValue="data.myAnswer ? data.myAnswer.indexList[0] : null"
        >
          <div class="mb" v-for="(item, index) in data.contentList.optionList" :key="index">
            <a-radio :value="index">{{item.value}}</a-radio>
          </div>
        </a-radio-group>
      </div>
      <div class="answers__answer" v-if="data.type === 'CHECKBOX'">
        <a-checkbox-group
          :defaultValue="data.myAnswer ? data.myAnswer.indexList : null"
          :disabled="true"
        >
          <div class="mb" v-for="(item, index) in data.contentList.optionList" :key="index">
            <a-checkbox :value="index">{{item.value}}</a-checkbox>
          </div>
        </a-checkbox-group>
      </div>
      <div class="answers__answer" v-if="data.type === 'OPEN'">
        <a-textarea
          placeholder="请输入"
          :auto-size="{ minRows: 2, maxRows: 6 }"
          v-model="value"
          @change="inputFun"
        />
      </div>
      <div class="answers__answer" v-if="data.type === 'SUBJECTIVE_SCORE'">
        范围1分~10分
        <a-input-number
          :min="1"
          :defaultValue="data.myAnswer ? data.myAnswer.indexList[0] : null"
          :max="10"
          getDetail
        />
      </div>
      <div class="flex answers__real">
        <div class="real">正确答案：</div>
        <div class="answers__real-label">{{data.answerList}}</div>
      </div>
      <div class="answers__analysis">
        <div>答案解析</div>
        <div class="answers__analysis-explain">{{data.answerExplain}}</div>
      </div>
    </div>
  </div>
</template>
 
<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      value: "",
      rightKey: "",
      rightArr: []
    };
  },
  computed: {
    change: function() {
      return function(index) {
        return String.fromCharCode(index + 65);
      };
    }
  },
  created() {
    this.getNum();
  },
  mounted() {
    console.log(this.data, "我是data");
  },
  methods: {
    getNum() {
      let arr = "";
      this.data.answerList = "";
      this.data.content.optionList.forEach((item, index) => {
        if (item.answerFlag === 1) {
          arr += '【' + (index + 1) + '-' + item.value + '】';
          this.data.answerList = arr;
        }
      });
      console.log(this.data, "data");
    }
  }
};
</script>
 
<style lang = "scss" scoped>
.mb {
  margin-bottom: 10px;
}
.answers {
  background: #FFFFFF;
  box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.05);
  border-radius: 15px;
  margin-top: 22px;
  overflow: hidden;
  &__row {
    background: #f1f1f1;
    padding: 0 40px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333;
    line-height: 60px;
  }
  &__score {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
  }
  &__content {
    padding: 28px 40px;
  }
  &__question {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }
  &__type {
    color: #ff5613;
    margin-right: 10px;
    white-space: nowrap;
  }
  &__answer {
    padding: 26px 40px 26px;
  }
  &__analysis {
    font-size: 18px;
    padding-top: 20px;
    &-explain {
      padding: 5px 20px 0px 20px;
    }
  }
  &__real {
    font-size: 18px;

    /* padding: 0px 0px 26px; */
    &-label {
      color: red;
    }
  }
}
.real {
  white-space: nowrap;
}
::v-deep .ant-radio-wrapper {
  white-space: pre-wrap;
}
</style>