import axios from '@/utils/request'

// 分页列表
export const recommendList = (data) => {
  return axios.request({
    url: '/api/commonCourseTask/daily/list',
    data,
    method: 'get'
  })
}

// 云talk 直播/api/base/videoLive/list
export const getVideoLiveList = (data) => {
  return axios.request({
    url: '/api/base/videoLive/list',
    data,
    method: 'get'
  })
}

// 通过id查询视频
export const getVideoDetails = (data) => {
  return axios.request({
    url: '/api/base/videoLive/queryById',
    data,
    method: 'get'
  })
}


// 外部和线下课程/api/course/list
export const getCourseLiveList = (data) => {
  return axios.request({
    url: '/api/course/list',
    data,
    method: 'get'
  })
}
// 每日推荐/api/course/outer/list
// export const getCommonCourseTaskListDaily = (data) => {
//   return axios.request({
//     url: '/api/commonCourseTask/daily/list',
//     data,
//     method: 'get'
//   })
// }
// 学习课程任务
export const getCommonCourseTaskPage = (data) => {
  return axios.request({
    url: '/api/commonCourseTask/page',
    data,
    method: 'get'
  })
}

// 用户当前学习路径的进度
export const getlearningTaskPathCurrent = (data) => {
  return axios.request({
    url: '/api/learningTaskPath/current/path/progress',
    data,
    method: 'get'
  })
}

// 获取课程视频播放凭证
export const getVideoPlayerSafe = (data) => {
  return axios.request({
    url: '/api/polyv/video/playerSafe',
    data,
    method: 'get'
  })
}

// 获取课程详情
export const getCourse = (data) => {
  return axios.request({
    url: '/api/course/queryById',
    data,
    method: 'get'
  })
}

// 获取课程评论
export const getComment = (data) => {
  return axios.request({
    url: '/api/courseComment/queryByCourseId',
    data,
    method: 'get'
  })
}

// 获取课程资料
export const getPdfData = (data) => {
  return axios.request({
    url: '/api/course/datum',
    data,
    method: 'get'
  })
}

// 课程添加评论
export const setComment = (data) => {
  return axios.request({
    url: '/api/courseComment/queryByCourseId',
    data,
    method: 'post'
  })
}

// 上传课程记录
export const setCourseRecord = (data) => {
  return axios.request({
    url: '/api/learningTaskSubTaskUserStudyLog/add',
    data,
    method: 'post'
  })
}

// 获取问卷题目
export const getQuestionnaire = (data) => {
  return axios.request({
    url: '/api/questionnaire/question/list',
    data,
    method: 'get'
  })
}

// 提交问卷
export const submitAnswer = (data) => {
  return axios.request({
    url: '/api/questionnaire/submit/answer',
    data,
    method: 'post'
  })
}

// 获取试卷题目
export const getQuesition = (data) => {
  return axios.request({
    url: '/api/examinationPaper/question/list',
    data,
    method: 'get'
  })
}

// 提交试卷答案
export const submitTestAnswer = (data) => {
  return axios.request({
    url: '/api/examinationPaper/submit/answer',
    data,
    method: 'post'
  })
}

// 获取试卷详情
export const getTestPaper = (data) => {
  return axios.request({
    url: '/api/examinationPaper/queryById',
    data,
    method: 'get'
  })
}

// 获取通用课程目录
export const getCourseList = (data) => {
  return axios.request({
    url: '/api/commonCourseTask/course/list',
    data,
    method: 'get'
  })
}
// /api/examinationPaper/getWrongQuestion
export const getWrongQuestion = (data) => {
  return axios.request({
    url: '/api/examinationPaper/getWrongQuestion',
    data,
    method: 'get'
  })
}