<template>
  <div class="container margin">
    <div class="exam">
      <div class="exam__title text__center">{{name}}</div>
      <div class="exam__title text__center">错题解析</div>
      <!-- <div class="exam__tip text__center">满分{{score}}分，{{pass}}分通过，时长{{duration}}分钟</div> -->
      <div class="exam__content">
        <!-- <answers
          v-for="(item, index) in data"
          :key="index"
          :data="item"
          :index="index+1"
          @answersFun="answersFun"
        />-->
        <analysis v-for="(item, index) in data" :key="index" :data="item" :index="index+1"></analysis>
      </div>
      <div class="exam__btn">
        <a-button size="large" shape="round" style="width:220px" @click="backFun">关闭</a-button>
      </div>
    </div>
  </div>
</template>
 
<script>
import analysis from "./components/analysis.vue";
// import answers from "./components/answers.vue";
import { getWrongQuestion, getTestPaper } from "@/api/learning";
export default {
  components: { analysis },
  data() {
    return {
      isLoad: false, // 是否刷新页面
      batchCode: null,
      examinationPaperId: null,
      data: [
        // {
        //   answer: [0, 1],
        //   answerExplain: "1+1=2",
        //   title: "1+1",
        //   type: "RADIO",
        //   content: {
        //     optionList: [
        //       { answerFlag: 0, value: "12" },
        //       { answerFlag: 1, value: "2" }
        //     ]
        //   }
        // }
      ],
      id: "", // 考试id
      subTask: "", // 子任务id
      duration: null,
      name: "",
      score: "",
      pass: "",
      answersData: [],
      visible: false,
      confirmLoading: false
    };
  },
  created() {
    this.examinationPaperId = this.$route.query.examinationPaperId;
    this.batchCode = this.$route.query.batchCode;
    this.getWrongQuestion();
    this.getDetail();
  },

  methods: {
    getWrongQuestion() {
      const paramter = {
        examinationPaperId: this.examinationPaperId,
        batchCode: this.batchCode
      };
      Object.assign(paramter, this.queryParam);
      getWrongQuestion(paramter).then(res => {
        this.data = res.result;
        console.log(this.data, "我在这里");
      });
    },
    backFun() {
      this.$router.back();
    },
    getDetail() {
      getTestPaper({ id: this.examinationPaperId }).then(res => {
        this.name = res.result.name;
      });
    },
    // 每做一道题的回调
    answersFun() {
      // console.log(data, "data");
      // var isPush = true;
      // this.answersData.forEach(item => {
      //   if (item.id === data.id) {
      //     isPush = false;
      //     if (item.answer.type === "SUBJECTIVE_SCORE") {
      //       item.answer.score = data.answer.score;
      //     } else if (item.answer.type === "OPEN") {
      //       item.answer.text = data.answer.text;
      //     } else {
      //       item.answer.indexList = [];
      //       data.answer.indexList.forEach(ele => {
      //         item.answer.indexList.push(ele);
      //       });
      //     }
      //   }
      // });
      // if (isPush) {
      //   this.answersData.push(data);
      // }
    }
  }
};
</script>
 
<style lang = "scss" scoped>
.exam {
  /* margin: 36px 0 50px; */
  &__top {
    padding-bottom: 30px;
    border-bottom: 2px solid #d8d8d8;
  }
  &__text {
    margin: 0 12px;
    color: #666666;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
  }
  &__time {
    color: #ff3434;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
  }
  &__title {
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    margin: 50px 0 20px;
  }
  &__tip {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
  }
  &__content {
    margin-top: 50px;
  }
  &__pagination {
    margin-top: 40px;
    text-align: center;
  }
  &__btn {
    margin-top: 62px;
    text-align: center;
  }
}
</style>